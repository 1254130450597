<script setup>
  import orderOs from '@/views/order/compontent/order-os'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const $emit = defineEmits(['updataStatus'])

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    orderStatus: {
      type: String
    },
    orderModifyRecordList: {
      type: Array,
      default: () => {
        return []
      }
    }
  })

  const orderOsRef = ref(null)
  const data = reactive({
    //订单状态 0未支付，1支付中，2待发货，3已发货，4已收货，5关闭CLOSED
    orderStatusJO: { CLOSED: -1, NOPAID: 0, PAYING: 1, PAYMENT_FAILED: 2, UNDISTRIBUTED: 3, DISTRIBUTED: 4, RECEIPTED: 5 }
  })

  /**
   * 根据订单状态和状态数组判断是否高亮状态图标
   * @param orderstatus
   * @param statusId
   * @returns {boolean}
   */
  const disableDiv = (orderstatus, statusId) => {
    return data.orderStatusJO[orderstatus] < data.orderStatusJO[statusId]
  }

  // 跳转购买页
  const toPay = () => {
    // console.log(props.orderInfo, 'props.orderInfo')
    // orderOsRef.value.toPay(props.orderInfo)
    router.push({ name: 'OTHERPAYMENT', query: { orderId: `${props.orderInfo.orderNo}`, paymentType: `${props.orderInfo.paymentMethod}` } })
  }

  // 取消订单
  const cancelOrder = () => {
    console.log('取消订单')
    orderOsRef.value.cancelOrder(props.orderInfo, updataStatus)
  }

  // 增加评论
  const addComment = () => {
    orderOsRef.value.toEvaluate(props.orderInfo.orderNo)
  }

  // 查看发票
  const viewInvoice = () => {
    // 构建目标URL,打开新页面
    // const url = proxy.$router.resolve({
    //   path: '/orderInvoice',
    //   query: { orderId: props.orderInfo.orderNo }
    // }).href
    // window.open(url, '_blank')
    const url = `${process.env.VUE_APP_PAYMENT_API}services/orders/invoice/${props.orderInfo.orderNo}`
    window.open(url, '_blank')
  }

  const updataStatus = (status) => {
    $emit('updataStatus', status)
  }
</script>

<template>
  <div class="rowSC">
    <template
      v-if="disableDiv(props.orderInfo.orderStatus, 'DISTRIBUTED') && props.orderInfo.orderStatus !== 'CLOSED' && props.orderInfo.paymentMethod === 'COD'"
      data-remark="订单状态-取消订单"
    >
      <el-button class="btn hr-primary-btn ml20" data-remark="取消订单" @click="cancelOrder">{{ $t('Cancel') }}</el-button>
    </template>
    <template v-if="props.orderInfo.orderStatus === 'RECEIPTED'" data-remark="订单状态-已完成去评论">
      <template v-if="orderModifyRecordList[0].generatedSuccess && userStore.isDev">
        <div class="btn ml20 rowCC" data-remark="查看发票" @click="viewInvoice">
          <i class="iconfont icon-chakan chakan-icon" @click="closeFiltration(item)"></i>
          View Invoice
        </div>
      </template>
      <el-button class="btn hr-primary-btn ml20" data-remark="增加评论" @click="addComment">Review</el-button>
    </template>
    <template
      v-if="
        props.orderStatus === 'PendingPayment' &&
        (props.orderInfo.paymentMethod === 'CARD' || props.orderInfo.paymentMethod === 'EASY_PAISA' || props.orderInfo.paymentMethod === 'JAZZ_CASH')
      "
      data-remark="订单状态-购买"
    >
      <button class="btn hr-primary-btn ml20" type="primary" @click="toPay">Pay Now</button>
    </template>
  </div>
  <order-os ref="orderOsRef"></order-os>
</template>

<style lang="less" scoped>
  .btn {
    width: 148px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px !important;
    cursor: pointer;
  }
  .chakan-icon {
    font-size: 16px;
    margin-right: 8px;
  }
</style>
