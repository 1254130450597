<script setup>
  import { OrderStatus2 } from '@/enum/haier'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const formattedTime2 = inject('formattedTime2')
  const deliveredData = inject('deliveredData')

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    orderSummary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })

  // 传入状态更改状态专属图标和文本内容
  const getOrderStatusByType = (type) => {
    if (!type) return
    const status = OrderStatus2.find((status) => status.type === type)
    return status || null
  }

  // 赋值
  const copy = () => {
    JLTools.copyText(props.orderInfo.orderNo, 'Copy success')
  }
</script>

<template>
  <div class="rowBC status-box">
    <div>
      <div class="rowSC">
        <div class="order-no fontWeight600">Order number: {{ props.orderInfo.orderNo }}</div>
        <JLImg class="copy" :src="require('@/assets/imgs/order/copy.png')" @click="copy"></JLImg>
      </div>
      <template v-if="props.orderInfo.orderStatus === 'CLOSED'">
        <div class="status-text mt8">{{ props.orderInfo.closeRemark ? 'Order Cancelled' : 'Order Closed' }}</div>
        <div v-if="!props.orderInfo.closeRemark" class="hint-text fontWeight600 mt8" data-remark="订单关闭-超时">
          <span class="color909399"> Order was closed due to timeout </span>
        </div>
        <div v-else-if="props.orderInfo.closeRemark !== 'cancel'" class="hint-text fontWeight600 mt8" data-remark="订单关闭-供应商取消">
          <span class="color909399">{{ props.orderInfo.closeRemark }}</span>
        </div>
      </template>
      <template v-else-if="props.orderInfo.orderStatus === 'NOPAID' || props.orderInfo.orderStatus === 'PAYING'">
        <div class="status-text mt8">{{ getOrderStatusByType(props.orderInfo.orderStatus).lable }}</div>
        <div class="hint-text fontWeight600 mt8" v-if="formattedTime2">
          <span class="color909399">Remaining time before the order automatically cancels: </span>
          <span class="formatted-time">{{ formattedTime2 }}</span>
        </div>
      </template>
      <template v-else-if="props.orderInfo.orderStatus === 'RECEIPTED'">
        <div class="status-text mt8">{{ getOrderStatusByType(props.orderInfo.orderStatus).lable }}</div>
        <div class="hint-text fontWeight600 mt8" v-if="deliveredData">
          <span class="color909399">Delivered on: </span>
          <span>{{ deliveredData }}</span>
        </div>
      </template>
      <template v-else>
        <div class="status-text mt8">{{ getOrderStatusByType(props.orderInfo.orderStatus).lable }}</div>
        <div class="hint-text fontWeight600 mt8"><span class="color909399">Estimated Delivery: </span> {{ props.orderInfo.estimatedDelivery }}</div>
      </template>
    </div>
    <div class="num fontWeight600" v-if="props.orderSummary.amount">{{ JLTools.forPrice(props.orderSummary.amount) }}</div>
  </div>
</template>

<style lang="less" scoped>
  .status-box {
    padding: 24px;
    margin: 24px auto 16px;
    border-radius: 4px;
    border: 1px solid var(--Zcolor);
    background: #e6eef6;
    font-size: 14px;
    color: #303133;
    .fontWeight600 {
      font-weight: 600;
    }
    .formatted-time {
      margin-left: 2px;
      color: #e6a23c;
    }
    .order-no {
      font-size: 18px;
      line-height: 28px;
    }
    .copy {
      width: 18px;
      height: 18px;
      margin-left: 8px;
      cursor: pointer;
    }
    .status-text {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
    }
    .color909399 {
      color: #909399;
    }
    .mt8 {
      margin-top: 8px;
    }
    .num {
      font-size: 28px;
      line-height: 42px;
    }
  }
</style>
