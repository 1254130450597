import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch } from 'vue'

export const stepsStatusList = [
  {
    title: 'Order Placed',
    orderStatus: 'NOPAID',
    description: 'Placed on',
    icon: `${require('@/assets/imgs/order/Notebook.png')}`,
    iconHover: `${require('@/assets/imgs/order/NotebookHover.png')}`
  },
  {
    title: 'Awaiting Payment',
    orderStatus: 'PAYING',
    description: 'Remaining Time: ',
    icon: `${require('@/assets/imgs/order/paying.png')}`,
    iconHover: `${require('@/assets/imgs/order/paying.png')}`
  },
  {
    title: 'Packaging',
    orderStatus: 'UNDISTRIBUTED',
    description: 'Packaging on',
    icon: `${require('@/assets/imgs/order/Package.png')}`,
    iconHover: `${require('@/assets/imgs/order/PackageHover.png')}`
  },
  {
    title: 'Out for Delivery',
    orderStatus: 'DISTRIBUTED',
    description: 'Shipped on',
    icon: `${require('@/assets/imgs/order/Truck.png')}`,
    iconHover: `${require('@/assets/imgs/order/TruckHover.png')}`
  },
  {
    title: 'Delivered',
    orderStatus: 'RECEIPTED',
    description: 'Delivered on',
    icon: `${require('@/assets/imgs/order/Handshake.png')}`,
    iconHover: `${require('@/assets/imgs/order/HandshakeHover.png')}`
  },
  {
    title: 'Closed',
    orderStatus: 'CLOSED',
    description: 'Closed on',
    icon: `${require('@/assets/imgs/order/Closed.png')}`,
    iconHover: `${require('@/assets/imgs/order/Closed.png')}`
  }
]

const statusList = [
  { createdDate: '2024-08-07T08:58:08Z', createdDateFormat: 'Placed on 7 Aug at 13:58', id: 333, orderNo: '10879962112236', orderStatus: 'NOPAID' },
  {
    createdDate: '2024-08-07T08:58:08Z',
    createdDateFormat: null,
    id: 338,
    orderNo: '10879962112236',
    orderStatus: 'CLOSED'
  }
]

export const countdownTimer = (seconds, onUpdate) => {
  let interval = setInterval(() => {
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds % 3600) / 60)
    let secs = seconds % 60

    // 固定返回 "hh:mm:ss" 格式的时间字符串
    let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`

    // 调用回调函数，将生成的时间字符串传递出去
    onUpdate(formattedTime)

    seconds--

    if (seconds < 0) {
      clearInterval(interval)
      console.log('Countdown finished!')
    }
  }, 1000)
}
