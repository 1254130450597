<script setup>
  import { stepsStatusList } from './stepsStatus'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, watch, inject } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const formattedTime1 = inject('formattedTime1')

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    orderStatus: {
      type: String,
      default: ''
    },
    orderModifyRecordList: {
      type: Array,
      default: () => {
        return []
      }
    }
  })

  const checkImageUrl = require('@/assets/imgs/order/check.png')
  const activeStep = ref(1) // 修改为你需要的步骤，例如 1，2 或 3
  const data = reactive({
    stepList: [],
    stepIndex: 0,
    orderStatus: ''
  })

  const calcFlexBasis = (length) => {
    return `calc(100% / ${length})`
  }

  function formatDate(isoString) {
    if (!isoString) return ''
    const date = new Date(isoString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    return `${year}-${month}-${day} at ${hours}:${minutes}`
  }

  // 根据订单状态确认步骤条列表和符合前端展示的订单状态
  const confirmationStatusList = () => {
    let statusList = []
    if (props.orderStatus === 'PendingPayment') {
      // 步骤条包含NOPAID PAYING
      statusList = stepsStatusList.filter((status) => status.orderStatus === 'NOPAID' || status.orderStatus === 'PAYING')
    } else if (props.orderStatus === 'OrderClosed') {
      // 步骤条包含NOPAID Closed
      statusList = stepsStatusList.filter((status) => status.orderStatus === 'NOPAID' || status.orderStatus === 'CLOSED')
    } else {
      // 步骤条包含NOPAID UNDISTRIBUTED DISTRIBUTED RECEIPTED
      statusList = stepsStatusList.filter(
        (status) => status.orderStatus === 'NOPAID' || status.orderStatus === 'UNDISTRIBUTED' || status.orderStatus === 'DISTRIBUTED' || status.orderStatus === 'RECEIPTED'
      )
    }
    return statusList
  }

  // 处理步骤条列表
  const handleStepList = (list) => {
    const statusList = confirmationStatusList()
    statusList.forEach((step, index) => {
      const statusItem = list.find((status) => status.orderStatus === step.orderStatus)
      step.descriptionText = ''
      if (statusItem) {
        data.stepIndex = index
        const formattedDate = formatDate(statusItem.createdDate)
        // console.log(formattedDate, 'formattedDate')
        step.createdDate = statusItem.createdDate
        step.descriptionText = `${step.description} ${formattedDate}`
      } else {
        step.createdDate = ''
        step.descriptionText = ''
      }
    })
    // console.log(statusList, 'data.stepList')
    data.stepList = statusList
  }

  watch(
    () => props.orderModifyRecordList,
    (newVal, oldVal) => {
      console.log(newVal, oldVal, 'orderModifyRecordList')
      if (newVal.length > 0 && newVal !== oldVal) {
        handleStepList(newVal)
      }
    },
    {
      immediate: true
    }
  )
</script>

<template>
  <div>
    <div class="step-box">
      <div
        v-for="(step, index) in data.stepList"
        :key="index"
        class="step-item is-center"
        :style="{ flexBasis: calcFlexBasis(data.stepList.length) }"
        :class="{ 'is-active': index <= data.stepIndex }"
      >
        <div class="icon-head">
          <div class="step-icon">
            <JLImg class="icon-check" :src="checkImageUrl"></JLImg>
          </div>
          <div class="step-line"></div>
        </div>
        <div class="step-main">
          <JLImg :src="index <= data.stepIndex ? step.iconHover : step.icon" class="step-icon"></JLImg>
          <p class="title">{{ step.title }}</p>
          <p class="description" v-if="formattedTime1 && step.orderStatus === 'PAYING'">Remaining Time: {{ formattedTime1 }}</p>
          <p class="description" v-else>{{ step.descriptionText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .step-box {
    display: flex;
    .step-item {
      position: relative;
      flex-shrink: 1;
      /* &:first-child {
        .step-line {
          display: none;
        }
      } */
      &:last-child {
        .step-line {
          display: none;
        }
      }
      .icon-head {
        position: relative;
        width: 100%;
        .step-icon {
          position: relative;
          z-index: 1;
          width: 24px;
          height: 24px;
          background: #fff;
          border-radius: 50%;
          border: 2px solid var(--Zcolor);
          transition: 0.15s ease-out;
          .icon-check {
            width: 12px;
            height: 12px;
            margin-left: -1px;
          }
        }
        .step-line {
          position: absolute;
          /* left: -50%; */
          right: -50%;
          width: 100%;
          height: 6px;
          top: 9px;
          background-color: #e6eef6;
        }
      }
      .step-main {
        .step-icon {
          margin-top: 16px;
          width: 40px;
          height: 40px;
          opacity: 0.5;
          transition: 0.15s ease-out;
        }
        .title {
          margin-top: 12px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
        }
        .description {
          margin-top: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #909399;
        }
      }
    }
    .is-active {
      .icon-head {
        .step-icon {
          background: var(--Zcolor);
          .icon-check {
            display: block;
          }
        }
        .step-line {
          background-color: var(--Zcolor);
        }
      }
      .step-main {
        .step-icon {
          opacity: 1;
        }
      }
    }
  }

  .is-center {
    display: inline-block;
    .icon-head {
      text-align: center;
      .step-icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
    .step-main {
      text-align: center;
    }
  }
</style>
