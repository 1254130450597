<!-- 转账凭证处理, 需求[https://modao.cc/proto/S4s6uBryn6i1x0QaqJtW/sharing?view_mode=read_only&screen=rbpToPYNPiNXkTjTb] -->
<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, watch } from 'vue'
  import JLTools from '@/util/JLTools'
  import { mergeProperties } from '@/util/func'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    orderInfo: {
      type: Object,
      default: () => {
        return {
          mainOrderNo: ''
        }
      }
    }
  })
  const formRef = ref()
  const data = reactive({
    loading: false,
    dialogVisible: false,
    fromType: ''
  })
  const fromData = reactive({
    logicalDeleted: false, // 是否撤回|删除凭证
    mainOrderNo: '',
    amount: '',
    recordStatus: -2, //  0待审批，1审批通过，-1审批不通过 -2: 未提交
    userAccountNo: '', // 银行卡账号
    userAccountHoldName: '', // 银行名称
    userBankCredentials: '', // 银行凭据
    userPictureUrl: '' // 银行凭据图片
  })

  const rules = {
    userAccountNo: [{ required: true, message: 'can not be empty', trigger: 'blur' }],
    userAccountHoldName: [{ required: true, message: 'can not be empty', trigger: 'blur' }],
    userBankCredentials: [{ required: true, message: 'can not be empty', trigger: 'blur' }],
    userPictureUrl: [{ required: true, message: 'can not be empty', trigger: 'blur' }]
  }

  // watch(
  //   () => props.orderInfo,
  //   (newVal, oldVal) => {
  //     console.log(newVal.mainOrderNo, oldVal.mainOrderNo, 'newVal.oldVal')
  //     fromData.mainOrderNo = newVal.mainOrderNo
  //     fromData.amount = newVal.totalPaidAmount
  //     if (newVal.mainOrderNo !== oldVal.mainOrderNo) {
  //       resetData()
  //       bankTransfer()
  //     }
  //   }
  // )

  // 弹出弹窗
  const dialogVisibleShow = (order) => {
    if (order.mainOrderNo !== fromData.mainOrderNo) {
      fromData.mainOrderNo = order.mainOrderNo
      fromData.amount = order.totalPaidAmount
      resetData()
      bankTransfer()
    }
    data.dialogVisible = true
  }

  // 上传图片成功回调
  const uploadImg = (list) => {
    console.log(list, 'list')
    fromData.userPictureUrl = list && list.length ? list[0] : ''
  }

  // 操作凭证信息/请求|上传|修改|删除
  const bankTransfer = (type) => {
    type = type || 'get'
    console.log(fromData, 'data')
    data.loading = true
    api.order
      .bankTransfer(fromData, type)
      .then((res) => {
        console.log(res, 'bankTransfer')
        switch (type) {
          case 'get':
            // 查询银行转账凭据 =>/解决后端返回问题, 数组存在, 第一条为最新记录且记录未撤回
            if (res && res[0] && !res[0].logicalDeleted) {
              mergeProperties(fromData, res[0])
            }
            break
          case 'post':
          case 'put':
            // 上传|修改 银行转账凭据
            data.dialogVisible = false
            break
          case 'delete':
            // 撤回银行转账凭据
            resetData()
            break
          default:
            break
        }
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 重置成初始状态
  const resetData = () => {
    fromData.logicalDeleted = false
    fromData.recordStatus = -2
    fromData.userAccountNo = ''
    fromData.userAccountHoldName = ''
    fromData.userBankCredentials = ''
    fromData.userPictureUrl = ''
  }

  // 提交表单
  const submitFrom = (formEl, type) => {
    console.log('提交订单', formEl)
    if (!formEl) return
    formEl.validate((valid) => {
      console.log(valid, 'valid')
      if (valid) {
        bankTransfer(type)
      }
    })
  }

  // 撤回凭证
  const handleDelete = () => {
    proxy.$Message.confirm({
      title: `hint`,
      msg: `Do you want to withdraw theinformation you uploaded?`,
      onOk: () => {
        bankTransfer('delete')
      }
    })
  }

  defineExpose({
    dialogVisibleShow
  })
</script>

<template>
  <el-dialog v-model="data.dialogVisible" title="Transfer Information view" width="45%" center :close-on-click-modal="false">
    <div v-loading="data.loading">
      <div class="rowBC order-info-box">
        <div class="rowSC">
          <div>Order No:</div>
          <div class="ml10">{{ fromData.mainOrderNo }}</div>
        </div>
        <div class="rowSC">
          <div>Order No:</div>
          <div class="ml10">{{ fromData.amount }}</div>
        </div>
      </div>
      <el-form ref="formRef" :model="fromData" class="from-box mt20" label-width="160px" label-position="left" :rules="rules">
        <el-form-item label="Account Number:" prop="userAccountNo">
          <el-input v-model="fromData.userAccountNo" :readonly="fromData.recordStatus >= 0" />
        </el-form-item>
        <el-form-item label="Name of Account: " prop="userAccountHoldName">
          <el-input v-model="fromData.userAccountHoldName" :readonly="fromData.recordStatus >= 0" />
        </el-form-item>
        <el-form-item label="Bank Credentials:" prop="userBankCredentials">
          <el-input v-model="fromData.userBankCredentials" :readonly="fromData.recordStatus >= 0" />
        </el-form-item>
        <el-form-item label="Reason of Rejection:" prop="userPictureUrl">
          <img-upload :fileCount="1" :isDelete="fromData.recordStatus < 0" :imgs="fromData.userPictureUrl ? [fromData.userPictureUrl] : []" @imgsCallback="uploadImg"></img-upload>
        </el-form-item>
      </el-form>
    </div>

    <template #footer v-if="fromData.recordStatus < 1 && !data.loading">
      <span class="dialog-footer">
        <el-button @click="data.dialogVisible = false">Cancel</el-button>
        <!-- 未提交状态 -->
        <el-button v-if="fromData.recordStatus === -2" type="primary" @click="submitFrom(formRef, 'post')">Confirm</el-button>
        <!-- 审核拒绝状态可以修改订单 -->
        <el-button v-else-if="fromData.recordStatus === -1" type="primary" @click="submitFrom(formRef, 'put')">Modify</el-button>
        <!-- 待审核状态,可以撤回无法进行其他操作 -->
        <el-button v-else-if="fromData.recordStatus === 0" type="primary" @click="handleDelete">Withdraw</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>
  .order-info-box {
    height: 35px;
    /* background: #f2f2f2; */
  }
  .from-box {
    max-width: 360px;
  }
</style>
