<script setup>
  import { ElMessage, ElNotification } from 'element-plus'
  import credentialsDialog from '@/views/order/compontent/credentials-dialog'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const credentialsDialogRef = ref()

  // 增加评论
  const toEvaluate = (orderId) => {
    proxy.$router.push({
      name: 'ORDEREVALUATE',
      query: {
        orderId: orderId
      }
    })
  }

  // 已发货确定操作弹窗
  const confirmReceive = (order, callback) => {
    let title = proxy.$t('Confirm')
    let msg = proxy.$t('Do you confirm that you received the product?')
    proxy.$Message.confirm({
      title: title,
      msg: msg,
      okText: 'Confirm',
      onOk: () => {
        api.order
          .comfirmReceipt(order.mainOrderNo)
          .then((res) => {
            console.log('已发货确定操作弹窗', res)
            proxy.$toast(proxy.$t('Order received successfully'), { type: 'succeed' })
            if (callback) {
              callback({
                optType: 'comfirmReceipt',
                status: res
              })
            }
          })
          .catch((error) => {})
      }
    })
  }

  // 取消订单弹窗
  const cancelOrder = (order, callback, curIndex) => {
    const mainOrderNo = order.mainOrderNo || order.orderNo
    let title = proxy.$t('Cancel order')
    let msg = proxy.$t('Do you really want to cancel this order?')
    proxy.$Message.confirm({
      title: title,
      msg: msg,
      onOk: () => {
        // ElNotification({
        //   title: 'Success',
        //   duration: '3000',
        //   message: 'Order cancelled successfully.',
        //   type: 'success'
        // })
        api.order
          .cancelOrderNo(mainOrderNo)
          .then((res) => {
            proxy.$toast(proxy.$t('Order cancelled successfully'), { type: 'succeed' })
            if (callback) {
              callback({
                optType: 'cancel',
                status: res,
                index: curIndex ?? null
              })
            }
          })
          .catch((error) => {
            console.log(error, 'error')
            ElMessage({
              message: `${error.title}`,
              type: 'error'
            })
          })
      }
    })
  }

  // 跳转购买页
  const toPay = (order) => {
    console.log('去购买')
    router.push({ name: 'OTHERPAYMENT', query: { orderId: `${order.mainOrderNo}`, paymentType: `${order.channel}` } })
  }

  // 上传凭证处理
  const handleCredentials = (order) => {
    credentialsDialogRef.value.dialogVisibleShow(order)
  }

  // 跳转商品详情页
  const toProductDetail = (id) => {
    const { href } = router.resolve({
      name: 'productMain',
      params: {
        id: id
      }
    })
    window.open(href, '_blank')
  }

  defineExpose({
    toEvaluate,
    confirmReceive,
    cancelOrder,
    toPay,
    handleCredentials
  })
</script>

<template>
  <div>
    <credentials-dialog ref="credentialsDialogRef" />
  </div>
</template>
