<script setup>
  import { defineComponent, getCurrentInstance, onMounted, ref, reactive, toRefs, watchEffect } from 'vue'
  const props = defineProps({
    itemDetails: {
      type: Array
    },
    orderSummary: {
      type: Object,
      default: () => {
        return {}
      }
    }
  })
  const data = reactive({
    obj: '2312312',
    ceshi: [1, 2, 3, 4, 5]
  })
</script>

<template>
  <div class="order_main_card">
    <div class="title">
      Product <span>({{ props.itemDetails.length }})</span>
    </div>
    <div class="table-title table-flex">
      <div class="item Information">{{ $t('Product Information') }}</div>
      <div class="item2 Total">{{ $t('Unit Price') }}</div>
      <div class="item2 Quantity">{{ $t('Quantity') }}</div>
      <div class="item2 Total">{{ $t('Total') }}</div>
    </div>
    <div v-for="(spec, index) in props.itemDetails" :key="index" class="table-item">
      <div class="table-box table-flex">
        <div class="item pro-main rowSC">
          <JLImg class="pro-img" :src="spec.specCoverImg" />
          <div class="pro-info columnCC">
            <div class="pro-name text_hide2 tl">
              {{ spec.productName }}
            </div>
            <div class="pro-sx">
              {{ spec.specName }}
            </div>
          </div>
        </div>
        <div class="item2 Total">{{ JLTools.format_number(spec.amount) }}</div>
        <div class="item2 Quantity">× {{ spec.qty }}</div>
        <div class="item2 Total">{{ JLTools.forPrice(spec.amount * spec.qty) }}</div>
      </div>
    </div>
    <div class="table_footer" data-remark="总订单合计">
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Subtotal') }}:</span>
        <b class="value">{{ JLTools.format_number(props.orderSummary.amount) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Shipping fee') }}:</span>
        <b class="value">{{ JLTools.format_number(props.orderSummary.shippingFee) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Discount') }}:</span>
        <b class="value totle">{{ JLTools.format_number(props.orderSummary.discount) }}</b>
      </div>
      <div class="settlement_right_item">
        <span class="key mr10">{{ $t('Total Amount') }}:</span>
        <b class="value totle">{{ JLTools.format_number(props.orderSummary.total) }}</b>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
  .order_main_card {
    width: 100%;
    padding: 8px 16px 0;
    box-sizing: border-box;
    .title {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      span {
        color: #909399;
      }
    }
    .table-item {
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #DCDFE6;
    }
  }

  .order_main_card {
    .table-title {
      width: 100%;
      padding: 12px 24px;
      margin-top: 16px;
      border-radius: 4px;
      background: #e6eef6;
      color: #606266;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      box-sizing: border-box;
    }
    .table-flex {
      display: flex;
      align-items: center;
      align-self: stretch;
      gap: 48px;
      .item {
        width: 360px;
      }
      .item2 {
        flex: 1 0 0;
        text-align: center;
      }
    }
  }

  .table-box {
    margin-top: 8px;
    padding: 16px;
    .pro-main {
      .pro-img {
        width: 70px;
        height: 70px;
        cursor: pointer;
      }
      .pro-info {
        width: fit-content;
        height: 70px;
        padding-left: 15px;
        .pro-name {
          cursor: pointer;
          font-weight: 600;
        }
        .pro-sx {
          width: 100%;
          margin-top: 10px;
          color: #999;
        }
      }
    }
  }

  .order_main_card .table_footer {
    display: flex;
    width: 100%;
    padding: 15px;
    justify-content: flex-end;
    .settlement_right_item {
      margin-left: 30px;
    }
  }
</style>
